import {useEffect, useState} from 'react';
import cn from 'classnames';

import LocalAndGlobalCard from '@/pages/shopify.com/($locale)/_index/components/LocalAndGlobalSection/components/LocalAndGlobalCard';

import type {LocalAndGlobalCardsProps} from '../types';

export default function LocalAndGlobalCards({
  activeIndex,
  buttonText,
  countryCode,
  currencyCode,
  orderLabel,
  shirtPrice,
  sweaterPrice,
  products,
  locale,
}: LocalAndGlobalCardsProps) {
  const localizedShirtPrice = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  }).format(shirtPrice);
  const localizedSweaterPrice = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  }).format(sweaterPrice);
  const productsCount = products.length;
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  /**
   * When the current selected flag changes,
   * weather due to a useEffect or user interaction,
   * run the animation to loop to the next card.
   */
  useEffect(() => {
    setActiveCardIndex((prevIndex) => (prevIndex + 1) % productsCount);
  }, [activeIndex, productsCount]);

  return (
    <div
      className={cn(
        'relative mx-auto',
        'aspect-[165/217] w-[165px] h-[217px]',
        'sm:translate-x-[-22%] sm:aspect-[213/280] sm:w-[213px] sm:h-[280px]',
        'md:translate-x-0',
        'lg:aspect-[236/310] lg:w-[236px] lg:h-[310px]',
      )}
    >
      {products.map((product, index) => {
        const pillPrice =
          product.productType === 'shirt'
            ? localizedShirtPrice
            : localizedSweaterPrice;
        const isContinuousText = locale === 'ja' ?? false;

        const props = {
          activeCardIndex,
          buttonText,
          countryCode,
          currencyCode,
          image: product,
          index: index,
          pillText: orderLabel,
          pillPrice,
          productsCount,
          isContinuousText,
          locale,
        };

        return <LocalAndGlobalCard key={`card-${index}`} {...props} />;
      })}
    </div>
  );
}
